.section {
  min-height: 75vh;
  margin: 0 auto;
}
.container {
  display: flex;
  margin: 0 auto;
  padding: 4rem 0;
  gap: 2rem;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: calc(100% - 4rem);
  max-width: var(--max-size-container-3);
}

.fullscreen {
  display: table;
  padding-top: 6rem;
}
.fullscreenContainer {
  display: table-cell;
  vertical-align: middle;
}

.containerInput {
  max-width: var(--max-size-container-2);
  width: 100%;
}

.searchInput {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  gap: 0;
  border-radius: 1.5rem;
  background: var(--background-surface);
  /* box-shadow: var(--box-shadow); */
}
.searchInput label {
  font-size: 0;
}

.textareaContainer {
  position: relative;
  width: 100%;
}

.searchInput textarea {
  background: transparent;
  resize: none;
  position: relative;
  display: flex;
  float: none;
  height: auto;
  width: 100%;
  min-height: 3rem;
  line-height: 1.375rem;
  margin: 0;
  padding: 0.375rem 2.5rem 0.375rem 0;
  outline: none;
  color: var(--color-text-black);
  font-weight: 400;
  font-size: var(--font-size);
  letter-spacing: normal;
  text-align: left;
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition: all ease-in-out 0.25s;
}

.searchInput textarea::placeholder {
  color: var(--color-text-black);
  opacity: var(--opacity);
}

.searchActions {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  display: flex;
  align-items: center;
}

.searchButton {
  width: 2.25rem;
}

.searchButton,
.resetButton {
  font-size: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  background: var(--color-text-black);
  border-radius: var(--radius-full);
  border: 0;
}
.searchButton svg {
  color: var(--color-bg-white);
  transform: rotate(45deg);
  width: 16px;
  height: 16px;
}

.resetButton svg {
  width: 18px;
  height: 18px;
}

.resetButton svg {
  color: var(--color-text-black);
}

.resetButton {
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  background: transparent;
  color: var(--color-black);
  width: 1.5rem;
  height: 3rem;
  opacity: var(--opacity);
}
.resetButton:focus,
.resetButton:hover {
  opacity: 1;
}

.searchLogo {
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.5rem 1.5rem;
  opacity: var(--opacity);
}

/* Buttons */
.buttons {
  width: 100%;
  max-width: var(--max-size-container-2);
}
.buttons ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.buttons button {
  display: flex;
  align-items: center;
  gap: 0.425rem;
  padding: 0 0.75rem;
  border: 0;
  font-size: var(--font-size-small);
  font-weight: 400;
  color: var(--color-text-black);
  height: var(--size-small);
  border-radius: var(--radius-full);
  background: transparent;
}
.buttons button:hover,
.buttons button:focus {
  background: var(--background-surface);
}
.buttonActive {
  background: var(--background-surface) !important;
  opacity: 1 !important;
  color: var(--color-text-black) !important;
}

.buttons figure {
  background-repeat: no-repeat;
  background-position: center;
  filter: var(--svg-filter);
  height: 1rem;
  width: 1rem;
  background-size: 1rem 1rem;
}

@media screen and (max-width: 576px) {
  /* .buttons ul {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    text-align: center;
    display: inline-block;
  } */
  .buttons li {
    width: auto;
    display: inline-block;
  }
  .buttons button {
    margin: 0;
    font-size: 0.75rem;
    height: 1.5rem;
    padding: 1.15rem 0.625rem;
    gap: 0.25rem;
  }

  .searchLogo {
    height: 1.25rem;
    width: 1.25rem;
    background-size: 1.25rem 1.25rem;
  }

  .searchInput {
    padding: 0.375rem 0.85rem 0.375rem 1.25rem;
    border-radius: 1rem;
  }
  .searchButton {
    height: 2rem;
    width: 2rem;
  }
  .searchButton:hover svg,
  .searchButton:focus svg {
    opacity: 1;
  }
  .searchButton svg {
    width: 18px;
    height: 18px;
  }

  .resetButton svg {
    width: 18px;
    height: 18px;
  }

  .searchActions {
    right: 0.25rem;
  }

  .section {
    margin: 0 auto 3rem;
    padding: 1.5rem 0 0 0;
  }
  .container {
    width: calc(100% - 2rem);
    padding: 0;
    gap: 1rem;
  }

  .containerInput {
    padding: 0;
  }
}

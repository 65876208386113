.container {
  padding: 3rem 3rem 2.5rem;
  overflow: hidden;
  margin: 0 auto 2rem;
  max-width: var(--max-size-container-3);
  background: var(--background-linear-gradient);
  border-radius: var(--radius-big);
  color: var(--color-black);
  width: calc(100% - 4rem);
}

.title {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  margin-bottom: 1.5rem;
}
.title h3 {
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
}
.title svg {
  width: 1.25rem;
  height: 1.25rem;
}

.credits p {
  margin-top: 1.5rem;
  font-size: var(--font-size-small);
  width: 100%;
  text-align: right;
  opacity: var(--opacity);
}
.credits p a {
  transition: var(--transition);
}

@media screen and (max-width: 576px) {
  .container {
    padding: 2rem;
    width: calc(100% - 2rem);
    margin: 0 auto 1rem;
  }

  .title h3 {
    font-size: var(--font-size);
  }
}
